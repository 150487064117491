export const ViewportHeight = {
  mounted(el: any) {
    const setHeight = () => {
      const vh = window.innerHeight * 0.01;
      el.style.setProperty("height", `${vh * 100}px`);
    };
    setHeight();
    window.addEventListener("resize", setHeight);

    // 在组件销毁时移除事件监听，避免内存泄漏
    el.__vueSetHeight__ = setHeight;
  },
  unmounted(el: any) {
    window.removeEventListener("resize", el.__vueSetHeight__);
  },
};
