import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
import "./style/global.css"; // 确保使用正确的路径
// import { registerIcons } from "../utils/entry";

import { ViewportHeight } from "./directives/ViewportHeight";

const app = createApp(App);

// 注册自定义指令
app.directive("viewport-height", ViewportHeight);

// registerIcons(app); // antd内置icon
app.use(router);
app.use(Antd);
app.mount("#app");
